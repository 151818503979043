exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anfrage-tsx": () => import("./../../../src/pages/anfrage.tsx" /* webpackChunkName: "component---src-pages-anfrage-tsx" */),
  "component---src-pages-beitreten-tsx": () => import("./../../../src/pages/beitreten.tsx" /* webpackChunkName: "component---src-pages-beitreten-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-helft-mir-tsx": () => import("./../../../src/pages/helft-mir.tsx" /* webpackChunkName: "component---src-pages-helft-mir-tsx" */),
  "component---src-pages-hilf-mit-werde-mitglied-tsx": () => import("./../../../src/pages/hilf-mit/werde-mitglied.tsx" /* webpackChunkName: "component---src-pages-hilf-mit-werde-mitglied-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-neues-tsx": () => import("./../../../src/pages/neues.tsx" /* webpackChunkName: "component---src-pages-neues-tsx" */),
  "component---src-pages-quittung-tsx": () => import("./../../../src/pages/quittung.tsx" /* webpackChunkName: "component---src-pages-quittung-tsx" */),
  "component---src-pages-rechtliches-tsx": () => import("./../../../src/pages/rechtliches.tsx" /* webpackChunkName: "component---src-pages-rechtliches-tsx" */),
  "component---src-pages-spendenkonto-tsx": () => import("./../../../src/pages/spendenkonto.tsx" /* webpackChunkName: "component---src-pages-spendenkonto-tsx" */),
  "component---src-pages-wir-tsx": () => import("./../../../src/pages/wir.tsx" /* webpackChunkName: "component---src-pages-wir-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-3-tage-mittelalter-ferienprogramm-2021-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/3-tage-mittelalter-ferienprogramm-2021.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-3-tage-mittelalter-ferienprogramm-2021-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-ab-ins-beet-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/ab-ins-beet.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-ab-ins-beet-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-auf-ihn-mit-gebruell-denn-auch-helden-brauchen-manchmal-hilfe-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/auf-ihn-mit-gebruell-denn-auch-helden-brauchen-manchmal-hilfe.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-auf-ihn-mit-gebruell-denn-auch-helden-brauchen-manchmal-hilfe-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-christkindlexpress-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/christkindlexpress.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-christkindlexpress-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-crowdfunding-kampagne-fuer-silvester-gestartet-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/crowdfunding-kampagne-fuer-silvester-gestartet.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-crowdfunding-kampagne-fuer-silvester-gestartet-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-deinmoment-ein-tag-am-nuerburgring-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/deinmoment-ein-tag-am-nuerburgring.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-deinmoment-ein-tag-am-nuerburgring-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-ein-stueck-lebensfreude-gestiftet-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/ein-stueck-lebensfreude-gestiftet.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-ein-stueck-lebensfreude-gestiftet-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-eine-musikalische-reise-durch-die-welt-der-filmmusik-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/eine-musikalische-reise-durch-die-welt-der-filmmusik.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-eine-musikalische-reise-durch-die-welt-der-filmmusik-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-einen-beitrag-zum-auto-geleistet-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/einen-beitrag-zum-auto-geleistet.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-einen-beitrag-zum-auto-geleistet-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-ferienprogramm-2022-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/ferienprogramm-2022.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-ferienprogramm-2022-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-flagge-gezeigt-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/flagge-gezeigt.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-flagge-gezeigt-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-frauenliste-feiert-tag-der-heiligen-lucia-am-krippalkalender-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/frauenliste-feiert-tag-der-heiligen-lucia-am-krippalkalender.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-frauenliste-feiert-tag-der-heiligen-lucia-am-krippalkalender-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-hilfe-fuer-die-ukraine-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/hilfe-fuer-die-ukraine.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-hilfe-fuer-die-ukraine-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2020-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/jhv-2020.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2020-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2022-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/jhv-2022.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2022-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2023-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/jhv-2023.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2023-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2024-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/jhv-2024.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-jhv-2024-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-kinderlicht-e-v-laedt-schule-der-lebenshilfe-ins-kino-ein-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/kinderlicht-e-v-laedt-schule-der-lebenshilfe-ins-kino-ein.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-kinderlicht-e-v-laedt-schule-der-lebenshilfe-ins-kino-ein-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-kinderlicht-wallersdorf-e-v-weihnachtsaktion-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/kinderlicht-wallersdorf-e-v-weihnachtsaktion.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-kinderlicht-wallersdorf-e-v-weihnachtsaktion-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-klimapuzzle-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/klimapuzzle.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-klimapuzzle-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-konzert-der-filmmusik-2024-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/konzert-der-filmmusik-2024.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-konzert-der-filmmusik-2024-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-lasershow-premiere-in-wallersdorf-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/lasershow-premiere-in-wallersdorf.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-lasershow-premiere-in-wallersdorf-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-masken-fuer-ein-kinderlaecheln-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/masken-fuer-ein-kinderlaecheln.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-masken-fuer-ein-kinderlaecheln-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-mit-currywurst-beduerftige-kinder-unterstuetzt-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/mit-currywurst-beduerftige-kinder-unterstuetzt.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-mit-currywurst-beduerftige-kinder-unterstuetzt-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nachruf-1-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/nachruf-1.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nachruf-1-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nachruf-2-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/nachruf-2.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nachruf-2-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nachruf-3-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/nachruf-3.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nachruf-3-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-niederbayern-tv-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/niederbayern-tv.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-niederbayern-tv-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nikolausmarkt-frischer-bratapfelauflauf-war-der-renner-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/nikolausmarkt-frischer-bratapfelauflauf-war-der-renner.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-nikolausmarkt-frischer-bratapfelauflauf-war-der-renner-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-renovieren-in-4-waenden-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/renovieren-in-4-waenden.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-renovieren-in-4-waenden-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-schirmherrschaft-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/schirmherrschaft.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-schirmherrschaft-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-schneeball-2023-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/schneeball-2023.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-schneeball-2023-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-cb-bank-2023-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spende-cb-bank-2023.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-cb-bank-2023-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-der-kommunionkinder-haidlfing-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spende-der-kommunionkinder-haidlfing.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-der-kommunionkinder-haidlfing-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-in-hoehe-von-510-e-erhalten-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spende-in-hoehe-von-510e-erhalten.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-in-hoehe-von-510-e-erhalten-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-rk-2023-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spende-rk-2023.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-rk-2023-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-vom-bestattungsunternehmen-d-fischer-e-k-erhalten-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spende-vom-bestattungsunternehmen-d-fischer-e-k-erhalten.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spende-vom-bestattungsunternehmen-d-fischer-e-k-erhalten-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spendenuebergabe-an-den-vkkk-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spendenuebergabe-an-den-vkkk.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spendenuebergabe-an-den-vkkk-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spendenuebergabe-durch-agc-interpane-architectural-glass-gmbh-plattling-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/spendenuebergabe-durch-agc-interpane-architectural-glass-gmbh-plattling.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-spendenuebergabe-durch-agc-interpane-architectural-glass-gmbh-plattling-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-statt-kundengeschenke-kindern-helfen-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/statt-kundengeschenke-kindern-helfen.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-statt-kundengeschenke-kindern-helfen-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-tanzen-fuer-den-guten-zweck-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/tanzen-fuer-den-guten-zweck.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-tanzen-fuer-den-guten-zweck-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-valentinsaktion-an-der-kaufmaennischen-berufsschule-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/valentinsaktion-an-der-kaufmaennischen-berufsschule.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-valentinsaktion-an-der-kaufmaennischen-berufsschule-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-vier-jahreszeiten-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/vier-jahreszeiten.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-vier-jahreszeiten-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-wie-alles-begann-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/wie-alles-begann.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-wie-alles-begann-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-wohnung-fuer-ukrainische-familie-gesucht-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/kinderlicht.github.io/kinderlicht.github.io/src/content/blog/wohnung-fuer-ukrainische-familie-gesucht.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-wohnung-fuer-ukrainische-familie-gesucht-mdx" */)
}

